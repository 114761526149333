body {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal;
}
/* #info {
  position: relative;
  top: -10px;
  z-index: 2;
  text-align: center;
  font-weight: 500;
  padding: 10px;
} */
.survey-map {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
}

.prev-marked {
  position: absolute;
  top: 0px;
  left: 0px;
}

.right-panal {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.6);
}
.left-panal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 10vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.6);
}

.top-panal {
  position: absolute;
  top: 2vh;
  right: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-panal {
  position: absolute;
  bottom: 2vh;
  left: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom-panal_1 {
  position: absolute;
  bottom: 2vh;
  right: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-logo {
  position: relative;
  top: 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.client-logo img {
  max-width: 100%;
  max-height: 100%;
}

.client-logo .avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #858ce2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}

.measuretools {
  position: absolute !important;
  top: 45%;
  left: 5vw;
  width: 200px;
}

.measuretools .card-body {
  justify-content: space-between;
}

.text-measure {
  font-weight: 700;
  color: #1d70e0;
}
.middle-icon {
  position: absolute;
  top: 20px;
  left: 50%;
  height: 70px;
  width: 70px;
  background: #fff;
  margin-left: -35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.old-img {
  position: fixed;
  top: 130px;
  left: 5px;
  width: 110px;
  height: calc(100vh - 130px);
  overflow-y: auto;
}
.avatar-img {
  width: 100px;
  object-fit: contain;
  margin-bottom: 10px;
}
.dynamicvol {
  position: absolute !important;
  bottom: 0;
  right: 0;
  background-color: #fff;
  width: 450px;
}
.uploadvol {
  position: absolute !important;
  bottom: 0;
  right: 0;
  background-color: #fff;
  width: 650px;
}
.bg-gray {
  background-color: #eee;
}

/* sme dashboard css */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.sme-header {
  width: 100vw;
  background-color: #069b80;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.client-header {
  width: 100vw;
  background-color: #069b80;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.drogo-logo {
  width: 130px;
  object-fit: contain;
  display: flex;
  align-items: center;
}
.profile-width {
  width: 200px;
}
.profile-details {
  display: flex;
  flex-direction: column;
}

.content {
  width: 100vw;
  height: calc(100vh - 70px) !important;
  overflow-y: auto;
  display: block;
  background-color: #eee;
}

.modal-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.dashboard-client-img {
  width: 100%;
  background-image: url("../assets/svg-new/towerBg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
}
.dashboard-client-img1 {
  width: 100%;
  background-image: url("../assets/svg-new/proj-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
}

.client-logo-sme {
  position: absolute;
  top: 15px;
  left: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6e96ca;
  color: #fff;
}

.client-logo-sme img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.client-logo-sme-name {
  position: absolute;
  top: 20px;
  left: 65px;
  font-size: 16px;
  font-weight: bold;
  width: 200px;
  overflow: hidden;
}
.client-logo-sme-name1 {
  position: absolute;
  top: 15px;
  left: 65px;
  font-size: 16px;
  font-weight: bold;
  width: 200px;
  overflow: hidden;
}
.project-title {
  color: #069b80;
}
.color-blue-l {
  color: #00603e;
}
.badge_complete {
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
  background-color: #31880a !important;
  color: #fff !important;
  border-radius: 0px !important;
}
.badge_inprogress {
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
  background-color: #c39604 !important;
  color: #fff !important;
  border-radius: 0px !important;
}
.bread-last {
  color: #085088;
  cursor: pointer;
  font-size: 14px;
}
.text-gray {
  color: #9d9d9d;
}

.tower-tiles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-comp {
  color: #006fc4;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
.sme-tower .rdt_TableHeadRow {
  background-color: #f3f6f9 !important;
  color: #000 !important;
  font-weight: 500;
}
.sme-tower .rdt_TableCell {
  font-size: 16px !important;
}
.data-right {
  min-height: 900px;
  max-height: 900px;
  overflow-y: auto;
  overflow-x: hidden;
}

.data-left {
  min-height: 815px;
  max-height: 815px;
  background-image: url("../assets/svg-new/addComp-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
  overflow-x: hidden;
}
.data-left-1 {
  min-height: 80px;
  margin-bottom: 5px !important;
}
.row-height {
  min-height: 68vh;
  overflow-y: auto;
}
.color-lblue {
  color: #082395;
}
.color-grayl {
  color: #7e7e7e;
}
.add-comp {
  position: absolute;
  top: 45%;
  left: 45%;
  height: 70px;
  width: 70px;
}
.add-comp img {
  object-fit: contain;
  width: 100%;
}
.add-comp-span {
  position: absolute;
  top: 60%;
  left: 28%;
  font-size: 20px;
  color: #0180e2;
}
.data-right-active {
  color: #085088;
  text-decoration: underline;
  text-decoration-color: #085088;
  text-decoration-thickness: 3px;
  text-underline-offset: 10px;
}
.img-card-div {
  width: 100%;
}
.invisible {
  visibility: hidden;
}
.img-card-div img {
  object-fit: cover;
  width: 100%;
  height: 110px;
}
.sharedimg img {
  object-fit: contain;
  width: 100px;
  height: 100%;
}
.img-card-full {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  cursor: pointer;
}

.img-card-full1 {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  cursor: pointer;
}
.color-greenl {
  color: #00603e;
}

.img-workspace {
  position: relative;
  width: 97vw;
  height: 86vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background-color: #eee;
  overflow: hidden;
}
.show-img {
  width: 100%;
  height: 100%;
}
.show-img img {
  object-fit: contain;
  width: 100%;
}
.canvas-tool {
  position: absolute;
  right: 20px;
  top: 200px;
  z-index: 2;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.draw-file {
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #00ffff;
  padding: 10px;
  border-radius: 0px 2px 2px 0px;
  z-index: 2;
}
.draw-arr {
  position: absolute;
  top: 110px;
  left: 0;
  background-color: #069b80;
  padding: 10px;
  border-radius: 0px 10px 10px 0px;
  z-index: 2;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.obj-fit {
  object-fit: contain;
  width: 100%;
}
.close-img {
  position: absolute;
  top: 10px;
  right: 30px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #082395;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #fff;
}
.img-zoom {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  top: 80px;
  right: 23px;
  z-index: 2;
  width: 50px;
  background-color: #fff;
}

.canvas-draw {
  position: absolute;
  right: 100px;
  top: 50px;
  z-index: 2;
  background-color: #069b80;
  color: #fff;
  padding: 8px 12px;
  border-radius: 2px;
  font-size: 20px;
  cursor: pointer;
}

.canvas-next {
  position: absolute;
  z-index: 2;
  right: 30px;
  top: 50%;
}

.canvas-prev {
  position: absolute;
  z-index: 2;
  left: 30px;
  top: 50%;
}
.obs-modal {
  z-index: 3;
  max-height: 70vh;
  overflow-y: auto;
}
.book-img {
  width: 100%;
  height: 100%;
}
.book-img img {
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.row-height-book {
  height: 68vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.text-scrrenshot {
  color: #066255;
  font-size: 20px;
}
.book-x {
  position: absolute;
  top: -10px;
  right: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  background-color: #eee;
  cursor: pointer;
}
.thermal .accordion-header {
  color: #085088;
  font-size: 18px;
  background-color: #fff;
}
.icon-delete-file {
  position: absolute;
  top: -20px;
  right: -10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordion-body .rdt_TableHeadRow {
  background-color: #fff !important;
}
.comp-img {
  width: 100%;
  height: 250px;
}
.comp-img1 {
  width: 100%;
  height: 170px;
}
.comp-img1 img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.comp-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.del-icon {
  position: absolute;
  top: -185px;
  right: 10px;
  width: 15px !important;
  cursor: pointer;
}
.edit-icon {
  position: absolute;
  top: -185px;
  right: 40px;
  width: 15px !important;
  cursor: pointer;
}
.comp-name {
  position: absolute;
  top: 5px;
  left: 60px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.comp-status {
  position: absolute;
  top: 212px;
  right: 0;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px 0px 0px 0px;
}
.comp-status1 {
  position: absolute;
  top: 132px;
  right: 0;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px 0px 0px 0px;
}
.light-green {
  background-color: #086add;
}
.light-green2 {
  background-color: #0ab39c;
}
.color-danger {
  background-color: #ff0202;
}
.light-orange {
  background-color: #ebc505;
}
.dark-green {
  background-color: #3fc304;
}
.light-red {
  background-color: #bb2626;
}
.color-yellow {
  background-color: #d3d319;
}
.light-yellow {
  background-color: #bdbd16;
}
.detail-comp {
  background-color: #f3f6f9 !important;
  border: none !important;
}
.pos-fullscreen {
  position: relative;
  top: -100px;
  left: 45%;
  z-index: 2;
  color: #fff;
}
.off-img {
  width: 100%;
}
.off-img img {
  width: 100%;
  object-fit: fill;
}
.p-password-panal {
  display: none;
}
.br-1 {
  border-radius: 5px;
}
.client-address {
  position: absolute;
  bottom: 120px;
  width: 100%;
  padding: 5px;
  color: #ce3535;
  font-size: 20px;
  backdrop-filter: blur(10px);
}
.client-proj-img {
  width: 100%;
  height: 180px;
}
.client-proj-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.proj-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #fff;
  padding: 6px 8px;
  border-radius: 0px 0px 0px 6px;
}
.client-logo-client {
  position: absolute;
  top: 125px;
  left: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6e96ca;
  color: #fff;
}
.client-logo-client img {
  object-fit: fill;
  width: 100%;
}
.client-projectName {
  position: absolute;
  top: 130px;
  left: 65px;
  font-size: 20px;
  font-weight: 500;
}
.t-status-complete {
  padding: 5px;
  background-color: #13c56b0f;
  color: #13c56b;
}
.t-status-saveasdraft {
  padding: 5px;
  background-color: #13c56b0f;
  color: #50c3e6;
}
.t-status-inprogress {
  padding: 5px;
  background-color: #13c56b0f;
  color: #ffb100;
}
.t-status-inspectiondone {
  padding: 5px;
  background-color: #13c56b0f;
  color: #aaadaf;
}
.bottom-gallery {
  position: absolute;
  height: 160px;
  top: 80vh;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background-color: rgba(193, 215, 219, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s ease-in-out;
}

.prev-img-icon {
  position: absolute;
  top: 45%;
  left: 0;
  z-index: 2;
}
.next-img-icon {
  position: absolute;
  top: 45%;
  right: 10px;
}

.btm-img {
  height: 150px;
  margin-right: 10px;
}
.btm-img img {
  object-fit: fill;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 6px;
}
.btm-img img:hover {
  scale: 1.1;
}
.new-pagination {
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: end;
  font-size: 14px;
  padding: 2px;
}
.new-pagination button {
  font-size: 14px;
  width: 82px;
  padding: 4px;
  border-radius: 4px;
  margin-right: 5px;
}
.new-pagination input {
  width: 43px !important;
  padding: 4px;
  text-align: center;
  margin-right: 5px;
}
.new-pagination span {
  font-size: 17px;
  color: #9c9ea8;
  margin-right: 10px;
}
.dis-page {
  border: 1px solid #e9ebec;
  color: #464646 !important;
  background-color: #fff !important;
  border-radius: 4px;
}
.act-page {
  background-color: #069b80;
  border: 1px solid #e9ebec;
  color: #ffffff;
  border-radius: 4px;
}
.prev-pages {
  width: 80px !important;
}
.next-pages {
  width: 55px !important;
}

.wrap-text {
  overflow: hidden;
  height: 25px;
}

.prev-arrow {
  position: absolute;
  top: 30%;
  left: 0;
}
.prev-arrow1 {
  position: absolute;
  top: 15%;
  left: 0;
}
.prev-arrow img {
  width: 70%;
  object-fit: contain;
  cursor: pointer;
}
.next-arrow {
  position: absolute;
  top: 30%;
  right: -13px;
}
.next-arrow1 {
  position: absolute;
  top: 15%;
  right: 0px;
}
.next-arrow img {
  width: 70%;
  object-fit: contain;
  cursor: pointer;
}
.p-calendar .p-inputtext {
  padding: 8px !important;
}
.pos-inspection {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #000;
  background: rgba(252, 222, 141, 0.6);
}
.dot-img {
  position: relative;
  top: -55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot-img1 {
  position: relative;
  top: -5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot-img img {
  width: 2%;
  object-fit: contain;
}
.total-img {
  position: relative;
  top: -55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.main-img-src {
  min-height: 250px;
}
.main-img-src1 {
  max-height: 170px;
}
.croxx {
  position: absolute;
  top: 10px;
  right: 10px;
}
.corr-num {
  color: #4e8fe7;
}
.tx-fullmap {
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 92vh;
}
.tx-halfmap {
  position: relative;
  top: 30;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
}
.top-tx-panal {
  position: absolute;
  top: 100px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 400px;
}
.top-tx-panal1 {
  position: relative;
  top: -105%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 400px;
}
.tx-logo-client {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6e96ca;
  color: #fff;
}
.tx-projectName {
  font-size: 20px;
  font-weight: 500;
}

.tx-fullmap .ol-zoom {
  margin-top: 0 !important;
  display: flex;
}
.tx-fullmap .ol-zoom-in {
  height: 40px;
  width: 40px;
  font-size: 25px;
}

.tx-fullmap .ol-zoom-out {
  height: 40px;
  width: 40px;
  font-size: 25px;
}
.tx-halfmap .ol-zoom {
  margin-top: 0 !important;
  display: flex;
}

.tx-halfmap .ol-zoom-in {
  height: 40px;
  width: 40px;
  font-size: 25px;
}

.tx-halfmap .ol-zoom-out {
  height: 40px;
  width: 40px;
  font-size: 25px;
}
.tx-controls {
  position: absolute;
  top: 150px;
  left: 95%;
  z-index: 2;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #086add;
  padding: 10px;
}
.tx-controls3 {
  position: relative;
  top: -107%;
  left: 95%;
  z-index: 2;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #086add;
  padding: 10px;
}
.tx-controls img {
  width: 100%;
}
.tx-controls2 {
  position: absolute;
  top: 220px;
  left: 95%;
  z-index: 2;
  height: 300px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #086add;
  padding: 4px;
  padding-top: 10px;
}
.tx-controls4 {
  position: relative;
  top: -106%;
  left: 95%;
  z-index: 2;
  height: 240px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #086add;
  padding: 4px;
  padding-top: 10px;
}
.ol-attribution {
  visibility: hidden;
}

.tx-charts {
  background-color: #f3f6f9;
  padding-top: 20px;
}
.tx-charts .card-header {
  background-color: #fff !important;
}

.color-close {
  color: #ced4da;
}
.color-open {
  color: #29edab;
}
.text-open {
  color: #adb5bd;
}

.text-good {
  color: #3fc304;
}
.text-medium {
  color: #ffff00;
}
.text-high {
  color: #ff0202;
}
.text-major {
  color: #ff0202;
}
.text-minor {
  color: #ffff00;
}
.text-low {
  color: #086add;
}
.charts-content {
  height: 15vh;
  position: relative;
  top: -47%;
  padding-bottom: 20px;
}
.tx-coridor-select {
  position: absolute;
  width: 200px;
  top: 2%;
  right: 460px;
}
.max-height-tower {
  height: 88vh;
  overflow-y: auto;
}
.select-filter {
  position: relative;
  top: -52px;
  left: 27%;
}
.select-corr-report {
  position: relative;
  top: -90px;
  left: 88%;
  display: flex;
  align-items: center;
}
.img-excel {
  height: 30px;
  width: 30px;
}
.img-excel img {
  object-fit: contain;
  width: 100%;
}

.tx-tower-client .rdt_TableHeadRow {
  background: #0000002f !important;
}

.fw-500 {
  font-weight: 500;
}

.text-inprogress {
  color: #ed9600;
}
.text-complete {
  color: #13c56b;
}

.text-inspection {
  color: #50c3e6;
}
.text-saveasdraft {
  color: #086add;
}

.img-tx-tower {
  width: 100%;
}
.img-tx-tower img {
  object-fit: contain;
  width: 100%;
}
.nav-tower {
  border: 1px solid #000;
  padding: 2px;
}
.nav-component .nav-link.active {
  color: #003975 !important;
  background: none !important;
  border-bottom: 1px solid #003975;
}
.details .nav-link.active {
  background-color: #000 !important;
  color: #fff !important;
  /* text-decoration: underline !important; */
}

.client-img-offcanvas {
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
  margin-right: 10px;
}
.client-img-offcanvas img {
  object-fit: contain;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.remove-img {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background: red;
  border-radius: 5px;
}

.details .tab-content > .active {
  height: 420px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.text-green-off {
  color: #00603e;
}

.videobgimg {
  width: 100%;
  height: 60px;
}
.videobgimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.xsd {
  display: flex;
  align-items: center;
  position: absolute;
  top: 75px;
  width: 300px;

  i:hover {
    color: #fff !important;
    cursor: pointer;
  }
}

.app .data .map {
  position: relative;
}
html,
body,
#cesiumContainer,
.leaflet-container {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.cesium-widget-credits {
  display: none !important;
}

.btn-3d {
  position: absolute;
  top: 75px;
  right: 85px;
  color: #fff;
}
.btn-3d:hover i {
  color: #fff !important;
}
.cesium-viewer-geocoderContainer {
  display: none !important;
}

.uploadexcel .page-title-box {
  background: none;
  margin-bottom: -10px !important;
}

.cancelxlx {
  position: absolute;
  top: 84px;
  right: 66px;
  background: red;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.siteForm {
  z-index: 99;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 90vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background: #fff;
  width: 600px;
}
.site-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid #eee;
  padding: 10px 15px;

  i {
    color: #003975;
    cursor: pointer;
  }
}

.site-body {
  padding: 10px 15px;
  max-height: 76vh;
  overflow-y: auto;
}

.add-site {
  position: absolute;
  bottom: 5px;
  right: 0;
  background: #fff;
  width: 600px;
  height: 450px;
  border-radius: 10px;
  border: 1px solid #eee;
}

.add-site-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid #eee;
  padding: 10px 25px;

  i {
    color: #003975;
    cursor: pointer;
  }
}

.add-site-body {
  padding: 10px 25px;
  border-bottom: 1px solid #eee;

  .location {
    color: #069b80;
    font-weight: 500;
    cursor: pointer;
    width: max-content;
  }

  .location:hover {
    text-decoration: underline;
  }
}
.surveyform {
  .modal-header {
    background-color: #fff !important;
    .modal-title {
      text-align: center;
    }
  }
}
.downloadbtn {
  background: transparent !important;
  border: 1px solid #003975 !important;
  color: #003975 !important;
}

.modal-body-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

.object-fit-cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  z-index: 1300 !important;
}

.media-fix {
  background-color: #f2f2f2;
  padding: 20px;
}

.close-btn-pos {
  position: absolute;
  right: 7px;
  top: -3px;
  color: red;
  height: 20px;
  width: 20px;
  background: white;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.media-upload {
  .add-site {
    height: 550px;
  }
}

.bx-end {
  position: absolute;
  right: 10px;
  color: red;
}

.uploaded-img {
  position: absolute;
  bottom: 150px;
  width: 100%;
  background-color: #fff;
  margin-left: -24px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.comp-createdFrom {
  position: absolute;
  top: 10px;
  background-color: #fff;
  left: 10px;
  height: 30px;
  width: 30px;
}

.video-pos {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
  max-height: 400px;
  max-width: 800px;
}

.makeStyles-controlsWrapper-11 {
  background: none !important;
}

.pos-end {
  position: absolute;
  right: 10px;
  color: #fff;
  cursor: pointer;
  z-index: 10000;
}

.legends {
  position: absolute;
  bottom: 130px;
  left: 110px;
  z-index: 5;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.legends2 {
  position: absolute;
  bottom: 20px;
  left: 30px;
  z-index: 5;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.direction-column {
  flex-direction: column;
}

.chartHeight {
  margin-top: 400px;
}

.selectable-column {
  cursor: pointer;
  color: #006fc4;
}

.map-container {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #0180e2;
}

.actions-map {
  position: absolute;
  top: 116px;
  right: 20px;
  background: #006fc4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 4px;
  img {
    height: 20px;
    width: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

.layers {
  position: absolute;
  bottom: 90px;
  left: 110px;
  z-index: 5;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.co-ordinates {
  position: absolute;
  bottom: 10px;
  left: 50%;
  background: #000;
  padding: 5px;
  border-radius: 4px;
  color: #fff;
}

.coordinate-on-map {
  position: absolute;
  bottom: 10px;
  left: 50%;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 10px;
}

.show-layers-on-map {
  position: absolute;
  bottom: 100px;
  left: 30px;
  height: 60px;
  width: 60px;
  background-color: #066255;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
}
